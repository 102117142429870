<template>
    <app-dialog-info
        class="app-dialog-allocation-completed"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Allocation completed</h2>
        </template>

        <template #body>
            <div class="wrap">
                <div class="content">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Adipisci asperiores enim mollitia impedit inventore minus aut.</p>
                </div>

                <div class="btns">
                    <button class="btn btn-primary" @click="close">Got it</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },
    },

    components: {
        appDialogInfo,
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },

    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-allocation-completed {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;

            p {
                font-weight: normal;
            }
        }

        .btns {
            .btn-primary {
                max-width: 210px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-allocation-completed {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>
import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    Range: {
        name: 'Range',
        required: true,
        format: /^64\d{7,10}$/,
    },

    CustomRange: {
        name: 'Custom range',
        required: true,
        format: /^64\d{7,13}$/,
    },
})
<template>
    <div id="page-range" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Number Ranges</router-link>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <h2 class="heading" v-if="is_edit_mode">Edit Details</h2>
                        <h2 class="heading" v-else>Add Details</h2>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-textarea
                                    v-model="range.Description"

                                    label="Description"

                                    :error="errors.Description"

                                    @change="errors.Description = null"
                                />

                                <app-dropdown-select
                                    v-model="range.NumberType"
                                    :options="number_type_options"

                                    label="Number Type"

                                    :error="errors.NumberType"

                                    @change="errors.NumberType = null"
                                />

                                <app-switch
                                    v-model="custom_range"

                                    :disabled="is_edit_mode"
                                >Custom range</app-switch>

                                <app-input
                                    v-model="range.RangeStart"

                                    label="Range Start"

                                    :required="true"

                                    :mask="range_mask"

                                    :readonly="is_edit_mode"

                                    :error="errors.RangeStart"

                                    @change="errors.RangeStart = null"
                                />

                                <app-input
                                    v-model="range.RangeEnd"

                                    label="Range End"

                                    :required="true"

                                    :mask="range_mask"

                                    :readonly="is_edit_mode"

                                    :error="errors.RangeEnd"

                                    @change="errors.RangeEnd = null"
                                />

                                <app-dropdown-select
                                    v-model="range.SPID"
                                    :options="service_providers"

                                    key-value="value"
                                    key-title="title"

                                    label="Service Provider"

                                    :readonly="is_edit_mode"
                                />
                            </div>
                        </div>
                    </div>

                    <app-error v-model="errors.save"></app-error>

                    <button class="btn btn-primary btn-delete1" v-if="is_edit_mode && !deleterangeconfirm" @click="deleterange1">Delete</button>
                    <button class="btn btn-primary btn-delete2" v-if="is_edit_mode && deleterangeconfirm"  @click="deleterange2">DELETE - Click to confirm</button>
                    <button class="btn btn-primary btn-delete2" v-if="is_edit_mode && deleterangeconfirm"  @click="deleterangeno">CANCEL</button>

                    <button class="btn btn-primary btn-save" v-if="is_edit_mode && !deleterangeconfirm"  :disabled="!is_changed" @click="save">Save</button>
                    <button class="btn btn-primary btn-save" v-if="!is_edit_mode && !deleterangeconfirm" :disabled="!is_changed" @click="save">Add</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appDropdownSelect from '@/components/app-dropdown-select'
import appSwitch from '@/components/app-switch'

import errMessage from '@/helpers/errMessage'

import RangeValidator from '@/validators/range-validator'

// import testRange from './test-range.json'

const EMPTY_RANGE = {
    Description: '',
    NumberType: '',
    RangeStart: '',
    RangeEnd: '',
    SPID: null,
}

export default {
    props: {
        uuid: { type: String, default: null },
        spid: { type: Number, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appDropdownSelect,
        appSwitch,
    },

    data() {
        return {
            custom_range: false,

            range: JSON.parse(JSON.stringify(EMPTY_RANGE)),
            original_range: JSON.parse(JSON.stringify(EMPTY_RANGE)),

            number_type_options: [
                'SIP',
                'Prepay',
                'Postpay',
                'Fixed',
            ],

            loading: false,
            deleterangeconfirm: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    created() {
        this.init()
        this.deleterangeconfirm = false
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
        this.deleterangeconfirm = false
    },

    methods: {
        init() {
            const SPID = +Object.keys(this.service_providers)[0]

            this.range.SPID = SPID
            this.original_range.SPID = SPID

            if (this.is_edit_mode) {
                this.getRange()
            }
        },

        getRange() {
            this.loading = true

            const params = {
                SPID: this.range_spid,
                UUID: this.range_uuid,
            }

            this.$store.dispatch('api_phonenumber/GetPhoneNumberRangeByUUID', params)
                .then(response => {
                    this.custom_range = response.IsCustomRange

                    this.original_range = JSON.parse(JSON.stringify(response))
                    this.range          = JSON.parse(JSON.stringify(response))

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        /*
            getRange() {
                const delay_in_ms = 1000

                this.loading = true

                setTimeout(() => {
                    this.original_range = JSON.parse(JSON.stringify(testRange))
                    this.range          = JSON.parse(JSON.stringify(testRange))

                    this.loading = false
                }, delay_in_ms)
            },
        */

        validation() {
            let is_valid = true

            this.errors = {}

            const fields = {
                RangeStart: {
                    rule: this.custom_range ? 'CustomRange' : 'Range',
                    message: 'Please, enter valid range',
                },
                RangeEnd:   {
                    rule: this.custom_range ? 'CustomRange' : 'Range',
                    message: 'Please, enter valid range',
                },
            }

            for (const key in fields) {
                if (RangeValidator.isRuleExists(fields[key].rule)) {
                    if (RangeValidator.isInvalid(fields[key].rule, this.range[key], fields[key].message)) {
                        this.errors[key] = RangeValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!this.errors.RangeStart && !this.errors.RangeEnd) {
                if (this.range.RangeStart.length != this.range.RangeEnd.length) {
                    this.errors.save = '"Range Start" and "Range End" should be the same length'
                    is_valid = false
                } else if (+this.range.RangeStart >= +this.range.RangeEnd) {
                    this.errors.save = '"Range End" must be greater than "Range Start"'
                    is_valid = false
                }
            }

            return is_valid
        },

        deleterange1() {
            this.deleterangeconfirm = true
        },

        deleterangeno() {
            this.deleterangeconfirm = false
        },

        deleterange2() {
            let params = {
                SPID: this.range.SPID,
                UUID: this.range.UUID,
            }
            this.$store.dispatch(`api_phonenumber/DeletePhoneNumberRangeByUUID`, params)
                .then(response => {
                    this.original_range = JSON.parse(JSON.stringify(response))
                    this.range          = JSON.parse(JSON.stringify(response))

                    this.errors = {}
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                        this.$emit('saved')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: false,
                        })
                    }
                })
                .catch(error => {
                    this.errors.save = errMessage(error)
                    this.loading = false

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        save() {
            if (this.validation()) {
                this.loading = true

                const action = this.is_edit_mode ? 'UpdatePhoneNumberRange' : 'AddPhoneNumberRange'

                const params = {
                    ...this.range,

                    SPID: this.range.SPID,

                    RangeStartInt: +this.range.RangeStart,
                    RangeEndInt: +this.range.RangeEnd,

                    ...(!this.is_edit_mode ? {
                        IsCustomRange: this.custom_range,
                    } : {}),
                }

                this.$store.dispatch(`api_phonenumber/${ action }`, params)
                    .then(response => {
                        this.original_range = JSON.parse(JSON.stringify(response))
                        this.range          = JSON.parse(JSON.stringify(response))

                        this.errors = {}
                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        /*
            save() {
                if (this.validation()) {
                    const delay_in_ms = 1000

                    this.loading = true

                    setTimeout(() => {
                        this.errors = {}
                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    }, delay_in_ms)
                }
            },
        */

        reset() {
            this.errors = {}
            this.deleterangeconfirm = false

            if (!this.is_edit_mode) {
                this.original_range = JSON.parse(JSON.stringify(EMPTY_RANGE))
            }

            this.range = JSON.parse(JSON.stringify(this.original_range))
        },
    },

    computed: {
        ...mapGetters([
            'available_service_providers',
            'current_spid',
        ]),

        range_mask() {
            return {
                regex: String.raw`^[1-9].*$`
            }
        },

        back_to() {
            return this.referrer && this.referrer.name == 'number-manager-ranges'
                ? this.referrer
                : { name: 'number-manager-ranges' }
        },

        range_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        range_spid() {
            return this.spid || this.$route.params.spid || this.current_spid
        },

        is_edit_mode() {
            return Boolean(this.range_uuid)
        },

        is_changed() {
            let is_changed = false

            for (const key in EMPTY_RANGE) {
                is_changed = this.range[key] != this.original_range[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },

        service_providers() {
            const service_providers = {}

            for (let i = 0, len = this.available_service_providers.length; i < len; i++) {
                service_providers[this.available_service_providers[i].SPID] = {
                    value: this.available_service_providers[i].SPID,
                    title: this.available_service_providers[i].Name,
                }
            }

            return service_providers
        },
    },

    watch: {
        uuid() {
            this.reset()

            if (this.is_edit_mode) {
                this.getRange()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-range {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 30px;
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        .form-group {
            .form-controls {
                .app-textarea {
                    height: 106px;
                }

                .app-textarea,
                .app-input,
                .app-switch,
                .app-dropdown-select {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            h2 {
                &.heading {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        button {
            &.btn-delete1 {
                max-width: 100%;
                margin-bottom: 20px;
                background-color: #ff3f3f;
            }
            &.btn-delete2 {
                max-width: 100%;
                margin-bottom: 20px;
                background-color: red;
            }
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    #page-range {
        .btn-back-to {
            margin-bottom: 16px;
        }

        .board {
            margin-bottom: 16px;
            padding: 16px 8px;

            .form-group {
                .form-controls {
                    .app-textarea,
                    .app-input,
                    .app-switch {
                        margin-bottom: 24px;
                    }
                }
            }
        }

        button {
            &.btn-delete1 {
                max-width: 100%;
                margin-bottom: 20px;
                background-color: #ff3f3f;
            }
            &.btn-delete2 {
                max-width: 100%;
                margin-bottom: 20px;
                background-color: red;
            }
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>
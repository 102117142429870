<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <range-details
                    :uuid="range_uuid"
                    :spid="range_spid"

                    ref="range-details"

                    @close-sidebar="closeSidebar"
                    @saved="getNumberRanges"
                ></range-details>
            </app-sidebar-details>
        </template>

        <div class="page-number-manager-ranges">
            <app-loader v-if="processing" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12 heading-box">
                        <h1 class="heading">Number Ranges</h1>

                        <div class="dropdown-select-box" v-if="with_service_provider_filter">
                            <span>Filter by Service Provider:</span>

                            <app-dropdown-select
                                v-model="filter.spid"
                                :options="service_providers"

                                key-value="SPID"
                                key-title="Name"

                                @change="onChangeServiceProvider"
                            />
                        </div>
                    </div>

                    <div class="col-12">
                        <app-error v-model="errors.show" :message="errors.message"></app-error>

                        <app-search-box
                            v-if="can_manage_ranges"
                            v-model="filter.word"

                            :show-clear-button="true"

                            @search="onSearch"
                        />

                        <app-table
                            class="number-ranges-table"

                            :class="can_manage_ranges ? 'with-service-provider' : 'without-service-provider'"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :cols="cols"
                            :rows="rows"

                            :loading="loading"

                            :selected-row="range_uuid"

                            @allocate-number="onAllocateNumber"
                            @edit-range="onEditRange"
                        />

                        <div class="actions">
                            <app-pagination
                                v-if="pagination.total"

                                v-model="pagination.page"

                                :total="pagination.total"
                                :limit="pagination.limit"
                                :page-range="pagination.range"

                                @change="onPageChange"
                            />

                            <button
                                v-if="can_manage_ranges"
                                class="btn btn-primary btn-add-new-range"
                                :disabled="loading"
                                @click="addNewRange"
                            >Add new range</button>
                            <div v-if="!can_manage_ranges"
                            class="range_info"
                            >Number Ranges must be validated by our team before being added.<br />Please get in contact with us to add new Number Ranges for you</div>
                        </div>
                    </div>
                </div>
            </div>

            <app-dialog-allocation-completed
                v-if="dialogs.allocation.completed.show"
                v-model="dialogs.allocation.completed.show"

                @close="closeAllocationCompletedDialog"
            />
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import rangeDetails from '@/views/number-manager/range'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appSearchBox from '@/components/app-search-box'
import appDropdownSelect from '@/components/app-dropdown-select'

import appDialogAllocationCompleted from './components/app-dialog-allocation-completed'

import errMessage from '@/helpers/errMessage'

// import testNumberRanges from './test-number-ranges.json'

const OPERATOR_SPID = 1

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        rangeDetails,

        appLoader,
        appError,
        appTable,
        appPagination,

        appSearchBox,
        appDropdownSelect,

        appDialogAllocationCompleted,
    },

    data() {
        return {
            filter: {
                spid: null,
                word: '',

                sort: {
                    field: 'NumberType',
                    order: 'asc',
                },
            },

            sort: [
                { field: 'NumberType', title: 'Number Type', },
                { field: 'RangeStart', title: 'Range Start', },
                { field: 'RangeEnd',   title: 'Range End',   },
            ],

            number_ranges: [],

            loading: false,
            processing: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                allocation: {
                    completed: {
                        show: false,
                    },
                },
            },

            sidebar: {
                create: false,
                edit: false,
            },

            range_uuid: null,
            range_spid: null,

            is_mobile_mode: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.can_manage_ranges && this.current_spid == this.operator_spid) {
                this.filter.spid = null;
            } else {
                this.filter.spid = this.current_spid;
            }            

            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getNumberRanges()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getNumberRanges()
        },

        onPageChange(page) {
            this.getNumberRanges(page)
        },

        onChangeServiceProvider() {
            this.getNumberRanges(1)
        },

        onSearch() {
            this.getNumberRanges(1)
        },

        getNumberRanges(page) {
            this.loading = true

            const params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            params.SPID = this.filter.spid

            if (this.filter.word) {
                params.SmartSearch = this.filter.word
                params.PhoneNumberInRange = this.filter.word
            }

            this.$store.dispatch('api_phonenumber/GetPhoneNumberRangesPaginated', params)
                .then(response => {
                    if (response.PhoneNumberRanges) {
                        this.number_ranges = response.PhoneNumberRanges
                    } else {
                        this.number_ranges = []
                    }

                    if (response.PageInfo) {
                        this.pagination.page = response.PageInfo.PageNumber
                        this.pagination.total = response.PageInfo.TotalItemCount

                        if (this.$route.params.page != this.pagination.page
                            && !(
                                this.$route.params.page === undefined
                                && this.pagination.page === 1
                            )
                        ) {
                            this.$router.push({
                                name: this.$route.name,
                                params: { page: this.pagination.page },
                                replace: true,
                            })
                        }
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.number_ranges = []

                    this.loading = false
                })
        },

        showAllocationCompletedDialog() {
            this.dialogs.allocation.completed.show = true
        },
        closeAllocationCompletedDialog() {
            this.dialogs.allocation.completed.show = false
        },

        onAllocateNumber(row) {
            this.processing = true

            /**
             * @todo
             * I am getting the error message "CustomerUUID must be a valid UUID"
             */

            const params = {
                SPID: row.SPID,
                UUID: row.uuid,
            }

            this.$store.dispatch('api_phonenumber/AllocatePhoneNumberFromRange', params)
                .then(() => {
                    this.showAllocationCompletedDialog()

                    this.processing = false
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.processing = false
                })
        },

        /*
            onAllocateNumber(row) {
                const delay_in_ms = 1000

                this.processing = true

                setTimeout(() => {
                    this.showAllocationCompletedDialog()
                    this.processing = false
                }, delay_in_ms)
            },
        */

        onEditRange(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'number-manager-range-edit', params: { uuid: row.uuid, spid: row.SPID  } })
            } else {
                this.range_uuid = row.uuid
                this.range_spid = row.SPID

                this.sidebar.create = false
                this.sidebar.edit   = true

                this.openSidebar()
            }
        },

        addNewRange() {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'number-manager-range-create' })
            } else {
                this.range_uuid = null
                this.range_spid = null

                this.sidebar.create = true
                this.sidebar.edit   = false

                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.range_uuid = null
            this.range_spid = null

            this.$emit('close-sidebar')

            this.$refs['range-details'].reset()
        },
    },

    computed: {
        ...mapGetters([
            'available_service_providers',
            'is_super_admin',
            'current_spid',

            '$mobile_size',
        ]),

        can_manage_ranges() {
            return this.is_super_admin
        },

        operator_spid() {
            return OPERATOR_SPID
        },

        service_providers() {
            const service_providers = {
                0: {
                    SPID: null,
                    Name: 'All',
                }
            }

            for (let i = 0, len = this.available_service_providers.length; i < len; i++) {
                service_providers[this.available_service_providers[i].SPID] = this.available_service_providers[i]
            }

            return service_providers
        },

        with_service_provider_filter() {
            return Object.keys(this.service_providers).length > 2 && this.can_manage_ranges
        },

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.create: {
                    title = 'Add new range'
                } break

                case this.sidebar.edit: {
                    title = 'Edit number range'
                } break
            }

            return title
        },

        cols() {
            return [
                { key: 'Description', title: 'Description', multiline: true               },
                { key: 'NumberType',  title: 'Number Type', sort: { field: 'NumberType' } },
                { key: 'RangeStart',  title: 'Range Start', sort: { field: 'RangeStart' } },
                { key: 'RangeEnd',    title: 'Range End',   sort: { field: 'RangeEnd'   } },

                ...(this.can_manage_ranges
                    ? [ { key: 'SPIDName', title: 'Service Provider' } ]
                    : []
                ),

                // { key: 'allocate', action: 'allocate-number', icon: 'allocate', behavior: 'detached' },
                { key: 'edit',     action: 'edit-range',      icon: 'edit',     behavior: 'detached' },
            ]
        },

        rows() {
            return this.number_ranges.map(range => {
                return {
                    uuid: range.UUID,
                    Description: range.Description ? range.Description : '?',
                    NumberType: range.NumberType ? range.NumberType : '?',
                    RangeStart: range.RangeStart ? range.RangeStart : '?',
                    RangeEnd: range.RangeEnd ? range.RangeEnd : '?',

                    SPID: range.SPID,

                    SPIDName: range.SPID && this.service_providers[range.SPID]
                        ? this.service_providers[range.SPID].Name
                        : '?',

                    edit: {
                        disabled: !this.can_manage_ranges,
                    },
                }
            })
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-number-manager-ranges {
    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        .dropdown-select-box {
            display: flex;
            align-items: center;

            width: 100%;
            max-width: 425px;

            & > span {
                flex-shrink: 0;

                margin-right: 16px;

                font-size: 18px;
                color: var(--color-table-secondary);
            }

            // .app-dropdown-select {}
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .app-search-box {
        margin-bottom: 30px;
    }

    .number-ranges-table {
        &.with-service-provider {
            // @include table-cols-width((300px, 150px, 150px, 150px, 150px, 48px, 48px), true, 2);
            @include table-cols-width((300px, 150px, 150px, 150px, 150px, 48px), true, 1);
        }

        &.without-service-provider {
            // @include table-cols-width((300px, 150px, 150px, 150px, 48px, 48px), true, 2);
            @include table-cols-width((300px, 150px, 150px, 150px, 48px), true, 1);
        }
    }

    & > .container {
        & > .row {
            & > .col-12 {
                & > .actions {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .app-pagination,
                    .btn-add-new-range {
                        margin-top: 30px;
                    }

                    .range_info {
                        margin-top: 30px;
                        margin-right: 30px;
                        color: #afafaf;
                    }

                    .app-pagination {
                        flex-grow: 1;

                        margin-right: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-number-manager-ranges {
        .number-ranges-table {
            &.with-service-provider {
                // @include table-cols-width((250px, 130px, 130px, 130px, 130px, 48px, 48px), true, 2);
                @include table-cols-width((250px, 130px, 130px, 130px, 130px, 48px), true, 1);
            }

            &.without-service-provider {
                // @include table-cols-width((250px, 130px, 130px, 130px, 48px, 48px), true, 2);
                @include table-cols-width((250px, 130px, 130px, 130px, 48px), true, 1);
            }
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        flex-direction: column;

                        .app-pagination {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-number-manager-ranges {
        .heading-box {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 32px;

            .dropdown-select-box {
                margin-top: 16px;
            }
        }

        .app-search-box {
            margin-bottom: 16px;
        }

        .number-ranges-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        .app-pagination,
                        .btn-add-new-range {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}
</style>